import * as React from "react"
import Layout from "../components/layout"
import JobPostsFilterSub from "../components/jobPostsFilterSub"
import Seo from "../components/seo"
import JobsNav from "../components/jobsNav"
import JobsNavChefs from "../components/jobsNavChefs"

/*

Categories:

chefs = 2
front-of-house = 9
sales-marketing = 11
operations = 13
international = 4 

executive-chef

*/

const ExecutiveChef = () => (
  <Layout jobPage={true} splashPage={false} pageTitle="VACANCIES - CHEFS">
    <Seo title="Vacancies chefs" />
    <JobsNav link={"chefs"} />
    <JobsNavChefs link={"executive-chef"} />
    <JobPostsFilterSub category={2} subcategory={"Chefs - Executive Chef"} />
  </Layout>
)

export default ExecutiveChef
